import Navigation from '../navigation/Navigation';
import ProfileBar from '../profileBar/ProfileBar';

import classes from './Analytics.module.css';
import Grid from '@mui/material/Grid';
import { Stack, Typography, Box, List, ListItem, ListItemText, Card, CardContent, Divider } from '@mui/material';
import AnalyticsHeader from './widgets/AnalyticsHeader';
import AnalyticsIssuesCamerasByDay from './widgets/AnalyticsIssuesCamerasByDay';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import React, { useState } from 'react';
import { useEffect } from 'react';

// Import and register required Chart.js components
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { use } from 'react';
import { set } from 'lodash';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const Analytics = () => {
    const [selectedCountry, setSelectedCountry] = useState('All');
    const [selectedSite, setSelectedSite] = useState('All Sites');
    const [dataByCountry, setDataByCountry] = useState([]);
    const [countries, setCountries] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [serversCountries, setServersCountries] = useState([]);
    const [sites, setSites] = useState([]);
    const [siteData, setSiteData] = useState([]);

    // const dataByCountry = {
    //     All: {
    //         history: [
    //             { day: 'Monday', failed: 10, disabled: 5 },
    //             { day: 'Tuesday', failed: 15, disabled: 7 },
    //             { day: 'Wednesday', failed: 12, disabled: 6 },
    //             { day: 'Thursday', failed: 18, disabled: 8 },
    //             { day: 'Friday', failed: 20, disabled: 10 },
    //             { day: 'Saturday', failed: 8, disabled: 4 },
    //             { day: 'Sunday', failed: 9, disabled: 5 },
    //         ]
    //     },
    //     USA: {
    //         history: [
    //             { day: 'Monday', failed: 6, disabled: 3 },
    //             { day: 'Tuesday', failed: 9, disabled: 4 },
    //             { day: 'Wednesday', failed: 8, disabled: 3 },
    //             { day: 'Thursday', failed: 10, disabled: 5 },
    //             { day: 'Friday', failed: 12, disabled: 6 },
    //             { day: 'Saturday', failed: 5, disabled: 2 },
    //             { day: 'Sunday', failed: 7, disabled: 3 },
    //         ]
    //     },
    //     Colombia: {
    //         history: [
    //             { day: 'Monday', failed: 4, disabled: 2 },
    //             { day: 'Tuesday', failed: 6, disabled: 3 },
    //             { day: 'Wednesday', failed: 4, disabled: 2 },
    //             { day: 'Thursday', failed: 8, disabled: 3 },
    //             { day: 'Friday', failed: 8, disabled: 4 },
    //             { day: 'Saturday', failed: 3, disabled: 1 },
    //             { day: 'Sunday', failed: 2, disabled: 1 },
    //         ]
    //     },
    //     Panama: {
    //         history: [
    //             { day: 'Monday', failed: 8, disabled: 4 },
    //             { day: 'Tuesday', failed: 12, disabled: 6 },
    //             { day: 'Wednesday', failed: 8, disabled: 4 },
    //             { day: 'Thursday', failed: 16, disabled: 6 },
    //             { day: 'Friday', failed: 16, disabled: 8 },
    //             { day: 'Saturday', failed: 6, disabled: 2 },
    //             { day: 'Sunday', failed: 4, disabled: 2 },
    //         ]
    //     }
    // };

    //const sites = ['All Sites', 'Colombia - Central Office', 'Colombia - Barranquilla', 'Colombia - Cañasgordas'];
    // const siteData = {
    //     'All Sites': { failed: 35, disabled: 60 },
    //     'Colombia - Central Office': { failed: 20, disabled: 17 },
    //     'Colombia - Barranquilla': { failed: 10, disabled: 5 },
    //     'Colombia - Cañasgordas': { failed: 5, disabled: 28 },
    // };
    

    useEffect(() => {
        const getCountryData = async () => {
            try {
                const response = await fetch('https://vieratech.ai/projects/Salient-Dashboard-PHP/readEventsIssuesCountry.php?group=0');
                const responseDataCountries = await response.json();
                console.log("Final Country Data:", responseDataCountries);
                setSites(['All Sites', ...responseDataCountries['sitesNames']]);
                setSiteData(responseDataCountries['sitesT']);
                delete responseDataCountries['sitesT'];
                delete responseDataCountries['sitesNames'];
                setDataByCountry(responseDataCountries);
                setCountries(Object.keys(responseDataCountries));
                setHistoryData(responseDataCountries[selectedCountry].history.slice(0, 7));

            } catch (error) {
                console.error("Error in getCountryData:", error);
            }
        };
    
        getCountryData();
    }, []);
    

    // const countries = Object.keys(dataByCountry);
    // const historyData = dataByCountry[selectedCountry].history.slice(0, 7);

    const barChartData = {
        labels: historyData.map((entry) => entry.day),
        datasets: [
            {
                label: 'Alerts',
                data: historyData.map((entry) => entry.failed),
                backgroundColor: '#ffc107',
            },
            {
                label: 'Cameras Off',
                data: historyData.map((entry) => entry.disabled),
                backgroundColor: '#f44336',
            }
        ],
    };

    const pieChartData = {
        labels: ['Alerts', 'Cameras Off'],
        datasets: [
            {
                data: [
                    historyData.reduce((sum, entry) => sum + entry.failed, 0),
                    historyData.reduce((sum, entry) => sum + entry.disabled, 0),
                ],
                backgroundColor: ['#ffc107', '#f44336'],
            },
        ],
    };

    const handleCountryClick = (country) => {
        setSelectedCountry(country);
        setHistoryData(dataByCountry[country].history.slice(0, 7));
    };

    const handleSiteClick = (site) => {
        setSelectedSite(site);
    };

    const pieChartDataSites = {
        labels: ['Alerts', 'Cameras Off'],
        datasets: [
            {
                data: [
                    siteData[selectedSite]?.failed || 0,
                    siteData[selectedSite]?.disabled || 0,
                ],
                backgroundColor: ['#ffc107', '#f44336'],
            },
        ],
    };

    return (
        <Box className={classes.analiticsCenter}>
            <Stack direction="row" spacing={2}>
                <Box width="20%">
                    <Navigation />
                </Box>
                <Box width="80%" className={classes.divCenterDash} sx={{ backgroundColor: '#fff'}}>
                    <ProfileBar />
                    <AnalyticsHeader />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnalyticsIssuesCamerasByDay />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom style={{ textAlign: 'Left', fontWeight: 'bold' }}>
                                Analytics by Country (Last 7 days)
                            </Typography>
                            <Typography variant="h5" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {selectedCountry === 'All' ? 'All Countries' : selectedCountry}
                            </Typography>
                            <Card>
                                <CardContent>
                                    <Bar
                                        data={barChartData}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                        }}
                                        style={{ height: 400, width: '100%' }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Card style={{ backgroundColor: '#ffebee', textAlign: 'center', padding: '16px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="h6" style={{ color: '#666664', fontWeight: 'bold' }}>
                                            Cameras Off
                                        </Typography>
                                        <Typography variant="h4" style={{ color: '#FF6384', fontWeight: 'bold' }}>
                                        {historyData && Array.isArray(historyData) && historyData.length > 0 
                                        ? historyData.reduce((sum, entry) => sum + (entry.disabled || 0), 0) 
                                        : 0}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card style={{ backgroundColor: '#f5e9c1', textAlign: 'center', padding: '16px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="h6" style={{ color: '#666664', fontWeight: 'bold' }}>
                                            Alerts
                                        </Typography>
                                        <Typography variant="h4" style={{ color: '#d9a809', fontWeight: 'bold' }}>
                                        {historyData && Array.isArray(historyData) && historyData.length > 0 
                                        ? historyData.reduce((sum, entry) => sum + (entry.failed || 0), 0) 
                                        : 0}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Card style={{ height: 330, overflowY: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                                            Server List {selectedCountry === 'All' ? '' : `(${selectedCountry})`}
                                        </Typography>
                                        <List>
                                            {historyData.map((day) => {
                                                if(selectedCountry === 'All') {


                                                let tempCountries = Object.keys(day.servers);
                                                return (
                                                    <ListItem alignItems="flex-start"
                                                        key={day.day}
                                                        style={{ borderBottom: '1px solid #ddd', padding: '8px 16px' }}
                                                    >
                                                        <ListItemText
                                                            primary= {<Typography style={{ fontWeight: 'bold' }}>{day.day}</Typography>}
                                                            secondary={
                                                                <React.Fragment>
                                                                    {tempCountries.map((country) => (
                                                                        <>
                                                                        <Typography
                                                                            component="span"
                                                                            variant="body2"
                                                                            sx={{ color: 'rgb(54, 162, 235);', display: 'inline', fontWeight: 'bold' }}
                                                                        >
                                                                           {country}
                                                                        </Typography>
                                                                        <List>
                                                                            <ListItem>
                                                                                <Typography style={{ fontWeight: 'bold', marginRight: '10px' }}>Off: </Typography>
                                                                                {day.servers[country].disabled && day.servers[country].disabled.length > 0 ? day.servers[country].disabled.map((server) => (
                                                                                    <ListItemText
                                                                                    primary={<Typography style={{ fontWeight: 400 }}>{server}</Typography>}
                                                                                    />
                                                                                )) : <Typography style={{ fontWeight: 400 }}>None</Typography>}
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <Typography style={{ fontWeight: 'bold', marginRight: '10px' }}>Alert: </Typography>
                                                                                {day.servers[country].failed && day.servers[country].failed.length > 0 ? day.servers[country].failed.map((server) => (
                                                                                    <ListItemText
                                                                                    primary={<Typography style={{ fontWeight: 400 }}>{server}</Typography>}
                                                                                    />
                                                                                )) : <Typography style={{ fontWeight: 400, marginBottom: '16px' }}>None</Typography>}
                                                                            </ListItem>
                                                                        </List>
                                                                        
                                                                        </>
                                                                    ))}
                                                                </React.Fragment>
                                                            }
                                                            />
                                                        
                                                    </ListItem>
                                                );
                                            } else {
                                                let tempCountriesDisabled = day.serversDisabled ? day.serversDisabled : [];
                                                let tempCountriesFailed = day.serversFailed ? day.serversFailed : [];
                                                return (
                                                    <ListItem alignItems="flex-start"
                                                        key={day.day}
                                                        style={{ borderBottom: '1px solid #ddd', padding: '8px 16px' }}
                                                    >
                                                        <ListItemText
                                                            primary= {<Typography style={{ fontWeight: 'bold' }}>{day.day}</Typography>}
                                                            secondary={
                                                                <React.Fragment>
                                                                        <Typography
                                                                            component="span"
                                                                            variant="body2"
                                                                            sx={{ color: 'rgb(54, 162, 235);', fontWeight: 'bold', display: 'inline' }}
                                                                        >
                                                                           Off
                                                                        </Typography>
                                                                        {tempCountriesDisabled.map((servers) => (
                                                                            <>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                    primary={<Typography style={{ fontWeight: 400 }}>{servers}</Typography>}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                            
                                                                            </>
                                                                        ))} <br /><br />

                                                                        <Typography
                                                                            component="span"
                                                                            variant="body2"
                                                                            sx={{ color: 'rgb(54, 162, 235);', fontWeight: 'bold', display: 'inline' }}
                                                                        >
                                                                           Alert
                                                                        </Typography>
                                                                        {tempCountriesFailed.map((servers) => (
                                                                            <>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                    primary={<Typography style={{ fontWeight: 400 }}>{servers}</Typography>}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                            
                                                                            </>
                                                                        ))}

                                                                        
                                                                </React.Fragment>
                                                            }
                                                            />
                                                        
                                                    </ListItem>
                                                );
                                            }
                                            })}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Card style={{ height: 430, overflowY: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                <CardContent>
                                    <Pie data={pieChartData} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card style={{ height: 430, overflowY: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Countries
                                    </Typography>
                                    <List>
                                        {countries.map((country) => (
                                            <ListItem
                                                button
                                                key={country}
                                                onClick={() => handleCountryClick(country)}
                                                style={{ borderBottom: '1px solid #ddd', padding: '8px 16px' }}
                                            >
                                                <ListItemText
                                                    primary={<Typography style={{ fontWeight: selectedCountry === country ? 'bold' : 400 }}>{country}</Typography>}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        
                        {/* by site */}
                    </Grid>
                    <Divider style={{ margin: '16px 0' }} />
                    <Typography variant="h5" gutterBottom style={{ textAlign: 'Left', fontWeight: 'bold' }}>
                                Analytics by Site - Today
                            </Typography>
                            <Typography variant="h5" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {selectedSite === 'All' ? 'All Sites' : selectedSite}
                            </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Card style={{ backgroundColor: '#ffebee', textAlign: 'center', padding: '16px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="h6" style={{ color: '#666664', fontWeight: 'bold' }}>
                                            Cameras Off
                                        </Typography>
                                        <Typography variant="h4" style={{ color: '#FF6384', fontWeight: 'bold' }}>
                                            {siteData[selectedSite]?.disabled || 0}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card style={{ backgroundColor: '#f5e9c1', textAlign: 'center', padding: '16px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="h6" style={{ color: '#666664', fontWeight: 'bold' }}>
                                            Alerts
                                        </Typography>
                                        <Typography variant="h4" style={{ color: '#d9a809', fontWeight: 'bold' }}>
                                            {siteData[selectedSite]?.failed || 0}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Card style={{ height: 410, overflowY: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                <CardContent>
                                    <Pie data={pieChartDataSites} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card style={{ height: 410, overflowY: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Sites
                                    </Typography>
                                    <List>
                                        {sites.map((site) => (
                                                <ListItem
                                                    button
                                                    key={site}
                                                    onClick={() => handleSiteClick(site)}
                                                    style={{ borderBottom: '1px solid #ddd', padding: '8px 16px' }}
                                                >
                                                    <ListItemText
                                                        primary={<Typography style={{ fontWeight: selectedSite === site ? 'bold' : 400 }}>{site}</Typography>}
                                                    />
                                                </ListItem>
                                            ))}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        
                        {/* by site */}
                    </Grid>
                </Box>
            </Stack>
        </Box>
    );
};

export default Analytics;
