import ServersAddForm from "./ServersAddForm";
import { useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from 'react-i18next';

const ServersNew = (props) => {
    const { t } = useTranslation();
    const hiddeNewServerPopUp = () => {
        setNewServerPopUp(addServerButton);
    }

    const showAddUserPopUp = () => {
        setNewServerPopUp(
            <div>
                <ServersAddForm onCalcelSaveExp={hiddeNewServerPopUp} addServerListHandler={addServerListHandler}/>
            </div>
        );
    };

    const addServerListHandler = (newServerData) =>{
        props.addServerListHandler(newServerData);
    }

    const addServerButton = <Button variant="outlined" color="success"  onClick={showAddUserPopUp}>{t('Add New Server')}</Button>;
    const [newServerPopUp, setNewServerPopUp] = useState(addServerButton);

    return(
        <div class='my-8'>
            {newServerPopUp}
        </div>
    );
}

export default ServersNew;