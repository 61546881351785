import React, { useState, useEffect } from "react";
import { Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import ServerStatusChart from "./ServerStatusChart";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ServersList = (props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  // Filter servers based on the search input
  const filteredServers = props.filteredServers.filter((server) =>
    server.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="w-full px-16">
      <Typography className="text-xs font-bold mt-4 text-left">
        {t("Server List")} - Total: {props.serversCount}
      </Typography>

      {/* Autocomplete search field */}
      <TextField
        variant="outlined"
        placeholder={t("Search by Server Name")}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
        sx={{ marginY: 2 }}
      />

      <TableContainer component={Paper} sx={{ borderRadius: "10px", marginTop: "20px" }}>
        <Table aria-label="customized table" className="w-full">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Server")}</StyledTableCell>
              <StyledTableCell align="right">IP</StyledTableCell>
              <StyledTableCell align="right">{t("Port")}</StyledTableCell>
              <StyledTableCell align="right">{t("Status")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredServers.length > 0 ? (
              filteredServers.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.IP}</StyledTableCell>
                  <StyledTableCell align="right">{row.port}</StyledTableCell>
                  <StyledTableCell align="right">
                    <ServerStatusChart ip={row.IP} port={row.port} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={4} align="center">
                  {t("No records found")}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ServersList;
