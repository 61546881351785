import classes from './Footer.module.css';
import Grid from '@mui/material/Grid';

const Footer = () => {
    return(
        <div className={classes.footerBlock}>
        <Grid container spacing={0}>
            <Grid item xs={6.5} >
            </Grid> 
            <Grid item xs={5.5}>
                <img src="assets/logos/logo_mafi_footer.png" alt="Mafi Technologies" />
            </Grid>
        </Grid>
    </div>
    );
}

export default Footer;