import { useState } from "react";
import { Button } from "@mui/material";
import GroupsAddForm from "./GroupsAddForm";
import { useTranslation } from 'react-i18next';

const GroupsNew = (props) => {
    const { t } = useTranslation();
    const hiddeNewGroupPopUp = () => {
        setNewGroupPopUp(addGroupButton);
    }

    const showAddGroupPopUp = () => {
        setNewGroupPopUp(
            <div>
                <GroupsAddForm onCalcelSaveExp={hiddeNewGroupPopUp} addGroupListHandler={addGroupListHandler}/>
            </div>
        );
    };

    const addGroupListHandler = (newGroupData) =>{
        props.addGroupListHandler(newGroupData);
    }

    const addGroupButton = <Button variant="outlined" color="success"  onClick={showAddGroupPopUp}>{t('Add New Group')}</Button>;
    const [newGroupPopUp, setNewGroupPopUp] = useState(addGroupButton);

    return(
        <div class='my-8'>
            {newGroupPopUp}
        </div>
    );
}

export default GroupsNew;